import { FormValidate } from './';
const initFormValidate = (formSelector, excludeForms) => {
    //Найти все формы на странице
    const forms = document.querySelectorAll(formSelector);
    if (!forms)
        return;
    // Запустить валидацию для каждой формы.
    // Question: How make better
    // ([...forms] as HTMLFormElement[])
    // Array.from(forms)
    // ([...forms as unknown as []])
    Array.prototype.slice.call(forms)
        .filter((form) => !excludeForms.some((selector) => form.classList.contains(selector)))
        .forEach((form) => (new FormValidate({
        form: form,
        inputErrorTextSelector: '.field__error-text',
        formErrorTextSelector: '.form__error-text',
        botErrorTextSelector: '.form__error-bot-text'
    }).init()));
};
export default initFormValidate;
