const saveFormInfo = () => {
    // выбираем на странице все элементы типа textarea и input
    const inputs = document.querySelectorAll('textarea, input');
    inputs.forEach(input => {
        // если данные значения уже записаны в sessionStorage, то вставляем их в поля формы
        // путём этого мы как раз берём данные из памяти браузера, если страница была случайно перезагружена
        if (input.value === '')
            input.value = window.sessionStorage.getItem(input.name);
        // на событие ввода данных (включая вставку с помощью мыши) вешаем обработчик
        input.addEventListener('input', () => {
            // и записываем в sessionStorage данные, в качестве имени используя атрибут name поля элемента ввода
            window.sessionStorage.setItem(input.name, input.value);
        });
    });
};
export default saveFormInfo;
